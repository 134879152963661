import React, { useState } from 'react';

import triangleImg  from "../assets/images/triangle.png";

import { InfoSlide, CoordinatesPopup } from '../components/Popup';
import { ThousandsInput } from './Inputs';

/* Marker types */
export const PointDefinitions = {Distance: 0, Coordinates: 1, Triangle: 2}

/* Selection box for the different types of  */
function MarkerTypeSelect(props) {
    var options = []
    Object.keys(PointDefinitions).forEach((key) => { options.push(<option key={key} value={PointDefinitions[key]}>{key}</option>) });
    return (<select name="markerOptions" onChange={(e) => {props.onChange(e)}} value={props.value}>{options}</select>);
}

/* Options for distance markers */
export function DistanceOptions(props) {
    var ret = [];
    ret.push(<tr key="title">
        <th colSpan='5'>{props.t("markersgcp")}</th>
    </tr>);
    ret.push(<tr key="markerOptions"><td colSpan='5' className='center'>
        <MarkerTypeSelect onChange={(e) => {props.onPointDefChanged(e)}} value={props.pointDefinition} />
    </td></tr>);
    if(props.distances.length !== 0) {
        ret.push(<tr key="header"><td className='center'>{props.t("marker1")}</td><td className='center'>{props.t("marker2")}</td><td className='center'>{props.t("distance")}</td></tr>);
    }
    props.distances.forEach((distance, i) => {
        ret.push(
        <tr key={"dist_" + i}>
            <td><select name="markerPoint"   value={distance.point1}   onChange={(e) => props.onChange(i, parseInt(e.target.value), undefined, undefined)}>{ props.points }</select></td>
            <td><select name="markerPoint_2" value={distance.point2}   onChange={(e) => props.onChange(i, undefined, parseInt(e.target.value), undefined)}>{ props.points }</select></td>
            <td><input type="number"         value={distance.distance} onChange={(e) => props.onChange(i, undefined, undefined, parseFloat(e.target.value))} className='shortInput'/></td>
            <td>m</td>
            <td><button className="smallButton" onClick={() => props.onDelete(i)}>&times;</button></td> 
        </tr>);
    });
    ret.push(<tr key="add"><td colSpan='5' className='center'><button className="smallButton" onClick={() => props.onCreate()}>+</button></td></tr>);
    return ret;
}

/* Options for coordinate markers */
export function CoordinateOptions(props) {
    const [epsg, setEpsg] = useState(props.epsg);

    if(props.epsg !== epsg) { setEpsg(props.epsg); }

    var ret = [];
    ret.push(<tr key="info">
        <th colSpan='7'>{props.t("markersgcp")}</th>
        <td>
            <InfoSlide key="infoSlide" text={props.t("coordinatesin") + " " + epsg.name} />
        </td></tr>);
    ret.push(<tr key="select"><td colSpan='5' className='center'>
                <MarkerTypeSelect onChange={(e) => {props.onPointDefChanged(e)}} value={props.pointDefinition} />
            </td><td className='center' colSpan='3'><button className='smallButton' onClick={() => props.onOpenCsv()}>{props.t("loadfromcsv")}</button></td></tr>);
    if(props.coordinates.length !== 0) {
        ret.push(<tr key="header"><td className='center'>{props.t("marker")}</td><td colSpan='2' className='center'>{epsg.lng}</td><td colSpan='2' className='center'>{epsg.lat}</td><td colSpan='2' className='center'>{epsg.alt}</td></tr>);
    }
    props.coordinates.forEach((coord, i) => {
        ret.push(
        <tr key={"coord_" + i}>
            <td><select name="markerPoint" value={coord.point} onChange={(e) => props.onChange(i, parseInt(e.target.value), undefined, undefined)} onFocus={() => props.onFocusChanged(i)}>{ props.points }</select></td>
            <td className="alignRight"><ThousandsInput key={"x" + i} type="number" onChange={(val) => props.onChange(i, undefined, [val, coord.lat], undefined)} value={coord.lng} className='shortInput' onFocus={() => props.onFocusChanged(i)}/></td>
            <td>{epsg.lngUnit}</td>
            <td className="alignRight"><ThousandsInput key={"y" + i} type="number" onChange={(val) => props.onChange(i, undefined, [coord.lng, val], undefined)} value={coord.lat} className='shortInput' onFocus={() => props.onFocusChanged(i)}/></td>
            <td>{epsg.latUnit}</td>
            <td className="alignRight"><ThousandsInput key={"z" + i} type="number" onChange={(val) => props.onChange(i, undefined, undefined, val)} value={coord.alt} className='shortInput' onFocus={() => props.onFocusChanged(i)}/></td>
            <td>{epsg.altUnit}</td>
            <td><button className="smallButton" onClick={() => props.onDelete(i)}>&times;</button></td>
        </tr>);
    });
    ret.push(
        <CoordinatesPopup 
            t={props.t}
            key='coordsPopup' 
            open={props.popupOpen} 
            markers={props.points} 
            onLoadCoords={props.onLoadCoords}
            onClose={props.onClose}
        />);
    ret.push(<tr key='add'><td colSpan='8' className='center'><button className="smallButton" onClick={() => props.onCreate()}>+</button></td></tr>);
    return ret;
}

/* Options for triangle markers */
export function TriangleOptions(props) {
    var ret = [];
    ret.push(<tr key="title">
        <th colSpan='4'>{props.t("markersgcp")}</th>
        <td>
            <InfoSlide key="infoSlide" text={props.t("triangleinfo")} />
        </td>
    </tr>);
    ret.push(<tr key="markerOptions"><td colSpan='5' className='center'>
        <MarkerTypeSelect onChange={(e) => {props.onPointDefChanged(e)}} value={props.pointDefinition} />
    </td></tr>);
    ret.push(
    <tr key="add">
        <td colSpan='2'>
            <table><tbody>
                <tr><th></th><th></th><th>{props.t("point")}</th><th>{props.t("altitude")}</th></tr>
                <tr>
                    <td></td>
                    <td>A</td>
                    <td><select name="markerPoint" onChange={(e) => props.onChange({...props.triangle, point1: e.target.value})} value={(props.triangle != null) ? props.triangle.point1 : 0}>{props.points}</select></td>
                    <td><input type="number" onChange={(e) => props.onChange({...props.triangle, alt1: e.target.value})} value={(props.triangle != null) ? props.triangle.alt1 : ""} className='shortInput'/></td>
                    <td>m</td>
                </tr>
                <tr>
                    <td></td>
                    <td>B</td>
                    <td><select name="markerPoint" onChange={(e) => props.onChange({...props.triangle, point2: e.target.value})} value={(props.triangle != null) ? props.triangle.point2 : 0}>{props.points}</select></td>
                    <td><input type="number" onChange={(e) => props.onChange({...props.triangle, alt2: e.target.value})} value={(props.triangle != null) ? props.triangle.alt2 : ""} className='shortInput'/></td>
                    <td>m</td>
                </tr>
                <tr>
                    <td></td>
                    <td>C</td>
                    <td><select name="markerPoint" onChange={(e) => props.onChange({...props.triangle, point3: e.target.value})} value={(props.triangle != null) ? props.triangle.point3 : 0}>{props.points}</select></td>
                    <td><input type="number" onChange={(e) => props.onChange({...props.triangle, alt3: e.target.value})} value={(props.triangle != null) ? props.triangle.alt3 : ""} className='shortInput'/></td>
                    <td>m</td>
                </tr>
                <tr><th></th><th></th><th>{props.t("distance")}</th><th></th></tr>
                <tr>
                    <td></td>
                    <td>x</td>
                    <td><input type="number" onChange={(e) => props.onChange({...props.triangle, dist23: e.target.value})} value={(props.triangle != null) ? props.triangle.dist23 : ""} className='shortInput'/></td>
                    <td>m</td>
                </tr>
                <tr>
                    <td></td>
                    <td>y</td>
                    <td><input type="number" onChange={(e) => props.onChange({...props.triangle, dist31: e.target.value})} value={(props.triangle != null) ? props.triangle.dist31 : ""} className='shortInput'/></td>
                    <td>m</td>
                </tr>
                <tr>
                    <td></td>
                    <td>z</td>
                    <td><input type="number" onChange={(e) => props.onChange({...props.triangle, dist12: e.target.value})} value={(props.triangle != null) ? props.triangle.dist12 : ""} className='shortInput'/></td>
                    <td>m</td>
                </tr>
            </tbody></table>
        </td>
        <td colSpan='3'>
            <img src={triangleImg} className="triangleImg" alt=""/>
        </td>
    </tr>);
    return ret;
}