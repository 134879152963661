import React, { useState } from 'react';

/* Input type which adds ' to thousands */
export function ThousandsInput(props) {
    const addSeparator = (num) => {
        var splitStr = num.toString().split(".");
        var decimal = (splitStr.length > 1) ? "." + splitStr[1] : "";
        return splitStr[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'") + decimal
    };
    const removeNonNumeric = num => num.toString().replace(',', '.').replace(/[^0-9.]/g, "");

    const [textValue, setTextValue] = useState(addSeparator(parseFloat(props.value)));

    if(parseFloat(props.value) !== parseFloat(removeNonNumeric(textValue))) {
        setTextValue(addSeparator(props.value));
    }

    const handleChange = event => {
        var val = removeNonNumeric(event.target.value);
        setTextValue(addSeparator(val));
        props.onChange(parseFloat(val) || 0.00);
    }

    return (<input disabled={props.disabled} type="text" value={textValue} onInput={handleChange} className={props.className} onFocus={props.onFocus}/>);
};