import React, { useEffect } from "react";
import { Outlet } from "react-router";
import ReactGA from "react-ga4";

import { config } from "./Constants";

export function GA4App(props) {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Photomation", send_to: config.TRACK_ID_APP });
    }, []);

    return <Outlet />;
}

export function GA4Web(props) {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Photomation", send_to: config.TRACK_ID_WEP });
    }, []);

    return <Outlet />;
}